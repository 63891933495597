import React from "react"
import Layout from "../layout/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
// privacy-text
// terms-data
export default function CookiesPolicy() {
    return (
        <Layout>
            <SEO title={"Cookies Policy"} />

            <div className="terms-container">
                <div className="terms-header">
                    <p className="product-title text-center">Cookies Policy</p>
                </div>
                <div className="terms-data ct-wr">
                    <p className="privacy-text">
                        We use in our site{" "}
                        <Link className={"anchor-link"} to={"/"}>
                            https://www.sync.ai/
                        </Link>{" "}
                        <strong> ("Site") </strong>cookies and similar files or technologies to automatically collect
                        and store information about your computer, device, and Site usage, in order to improve their
                        performance and enhance your user experience. We use the general term
                        <strong> "cookies" </strong>in this policy to refer to these technologies and all such similar
                        technologies that collect information automatically when you are using our Site where this
                        policy is posted. You can find out more about cookies and how to control them in the information
                        below.
                    </p>
                </div>
                <div className="terms-data ct-wr">
                    <p className="privacy-text">
                        If you do not accept the use of these cookies, please disable them using the instructions in
                        this cookie policy or by changing your browser settings so that cookies from this Site cannot be
                        placed on your computer or mobile device. Important: disabling cookies on this Site may
                        seriously cripple the user experience and other features on the Site, to the point of rendering
                        them useless.
                    </p>
                </div>
                <div className="terms-data ct-wr">
                    <p className="privacy-text">
                        In this Cookies Policy, we use the term Sync.Me (and <strong>"we"</strong>,{" "}
                        <strong>"us"</strong> and <strong>"our"</strong>) to refer to Sync.MeTechnologies Ltd. Our
                        Privacy Policy is available at{" "}
                        <Link className={"anchor-link"} to={"/privacy-policy"}>
                            https://www.sync.ai/privacy-policy/
                        </Link>
                        .
                    </p>
                </div>
                <div className="terms-data ct-wr">
                    <h3>What is a cookie?</h3>
                </div>
                <div className="terms-data ct-wr">
                    <p>
                        Cookies are computer files containing small amounts of information which are downloaded to your
                        computer or mobile device when you visit a website. Cookies can then be sent back to the origin
                        at in website on each subsequent visit, or to another website that recognizes that cookie.
                        Cookies are widely used in order to make websites work, or to work more efficiently, as well as
                        to provide information to the owners of the website.
                    </p>
                </div>
                <div className="terms-data ct-wr">
                    <p>
                        Cookies do lots of different jobs, like letting you navigate between pages efficiently, remember
                        in your preferences, and generally improving the user experience. Cookies may tell us, for
                        example,whether you have visited our Site before or whether you are a new visitor.
                    </p>
                </div>
                <div className="terms-data ct-wr">
                    <p>There are two broad categories of cookies:</p>
                </div>
                <div className="terms-data ct-wr">
                    <ul>
                        <li>
                            <strong>First party cookies, </strong>
                            <span>served directly by us to your computer or mobile device.</span>
                        </li>
                        <li>
                            <strong>Third party cookies, </strong>
                            <span>
                                which are served by a third party on our behalf. We use third party cookies for
                                functionality, performance / analytics, marketing, unclassified and other technologies ,
                                and social media purposes. The third-party cookies are outside of our control. The third
                                parties may, at any time, change their terms of service, purpose and use of cookies,
                                etc. See below additional information on how to manage such cookies.
                            </span>
                        </li>
                    </ul>
                </div>
                <div className="terms-data ct-wr">
                    <p>
                        Cookies can remain on your computer or mobile device for different periods of time. Some cookies
                        are 'session cookies', meaning that they exist only while your browser is open. These are
                        deleted automatically once you close your browser. Other cookies are 'permanent cookies',
                        meaning that they survive after your browser is closed. They can be used by websites to
                        recognize your computer when you open your browser and browse the Internet again.
                    </p>
                </div>
                <div className="terms-data ct-wr">
                    <h3>Web beacons:</h3>
                </div>
                <div className="terms-data ct-wr">
                    <p>
                        Cookies are not the only way to recognize or track visitors to a website.We may use other,
                        similar technologies from time to time, like web beacons (sometimes called "tracking pixels" or
                        "clear gifs").These are small graphics files that contain a unique identifier that enable us to
                        recognize when someone has visited our website. This allows us, for example, to monitor the
                        traffic patterns of users from one page within our website to another, to deliver or communicate
                        with cookies, to understand whether you have come to our website from an online advertisement
                        displayed on a third party website, to improve website performance and to measure the success of
                        email marketing campaigns.In most instances, these technologies are reliant on cookies to
                        function, and therefore declining cookies prevents them from functioning.
                    </p>
                </div>
                <div className="terms-data ct-wr">
                    <p>
                        If you don't want your cookie information to be associated with your visits to these pages, you
                        can set your browser to turn off cookies as described further below. If you turn off cookies,
                        web beacon and other technologies will still detect your visits to our Site; however, they will
                        not be associated with information otherwise stored in cookies.
                    </p>
                </div>
                <div className="terms-data ct-wr">
                    <h3>Targeted advertising</h3>
                </div>
                <div className="terms-data ct-wr">
                    <p>
                        Third parties may drop cookies on your computer or mobile device to serve advertising through
                        our website. These companies may use information about your visits to this and other websites in
                        order to provide relevant advertisements about goods and services that you may be interested in.
                        They may also employ technology that is used to measure the effectiveness of advertisements. The
                        information collected through this process does not enable us or them to identify your name,
                        contact details or other personally identifying details unless you choose to provide these to
                        us.
                    </p>
                </div>
                <div className="terms-data ct-wr">
                    <h3>How do we use cookies?</h3>
                </div>
                <div className="terms-data ct-wr">
                    <p>We use cookies to:</p>
                </div>
                <div className="terms-data ct-wr">
                    <ul>
                        <li>
                            <p>track traffic flow and patterns of travel in connection with our Site; </p>
                        </li>
                        <li>
                            <p>
                                understand the total number of visitors to our Sites on an ongoing basis and the types
                                of internet browsers (e.g. Chrome, Firefox, Safari, or Internet Explorer) and operating
                                systems(e.g. Windows or Mac) used by our visitors;{" "}
                            </p>
                        </li>
                        <li>
                            <p>monitor the performance of our Site and to continually improve it; and</p>
                        </li>
                        <li>
                            <p>customize and enhance your online experience.</p>
                        </li>
                    </ul>
                </div>
                <div className="terms-data ct-wr">
                    <h3>What types of cookies do we use?</h3>
                </div>
                <div className="terms-data ct-wr">
                    <p>
                        The types of cookies used by us in connection with the Site can be considered 'essential website
                        cookies', 'functionality cookies', 'analytics and performance cookies', 'marketing',
                        'unclassified', and'other technologies' . We've set out some further information below, and the
                        purposes of the cookie swe set in the following table.
                    </p>
                </div>
                <div className="terms-data ct-wr">
                    <p className={"ol-list-type"}>1. Cookies necessary for essential website purposes</p>
                </div>
                <div className="terms-data ct-wr">
                    <p>
                        These cookies are essential to provide you with services available through this Site and to use
                        some of its features, such as access to secure areas. Without these cookies we will not be able
                        to provide services that you require, such as transactional pages and secure login accounts.
                    </p>
                </div>
                <div className="terms-data ct-wr">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <span className="font-bold">Cookie name</span>
                                </th>
                                <th>
                                    <span className="font-bold">Source</span>
                                </th>

                                <th>
                                    <span className="font-bold">Expiry</span>
                                </th>

                                <th>
                                    <span className="font-bold">Purpose</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <p>elementor</p>
                                </td>

                                <td>
                                    <p>sync.ai</p>
                                </td>

                                <td>
                                    <p>Persistent</p>
                                </td>

                                <td>
                                    <p>
                                        Used in context with the website'sWordPress theme.The cookie allows the website
                                        owner to implementor change the website's content in real-time
                                    </p>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <p>rc::a</p>
                                </td>

                                <td>
                                    <p>google.com</p>
                                </td>

                                <td>
                                    <p>Persistent</p>
                                </td>

                                <td>
                                    <p>
                                        This cookie is used to distinguish between humans and bots. This is beneficial
                                        for the website, in order to make valid reports on the use of their website.
                                    </p>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <p>rc::a</p>
                                </td>

                                <td>
                                    <p>google.com</p>
                                </td>

                                <td>
                                    <p>Session</p>
                                </td>

                                <td>
                                    <p>This cookie is used to distinguish between humans and bots</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="terms-data ct-wr">
                    <p className={"ol-list-type"}>2. Performance / Analytics Cookies</p>
                </div>
                <div className="terms-data ct-wr">
                    <p>
                        We use performance/analytics cookies to analyze how the website is accessed, used, or is
                        performing.We do this in order to provide you with a better user experience and to maintain,
                        operate and continually improve the website. For example, these cookies allow us to:
                    </p>
                </div>
                <div className="terms-data ct-wr">
                    <ul>
                        <li>
                            <p>
                                Better understand our website visitors so that we can improve how we present our
                                content;
                            </p>
                        </li>
                        <li>
                            <p>Test different design ideas for particular pages, such as our homepage;</p>
                        </li>
                        <li>
                            <p>
                                Collect information about Site visitors such as where they are located and what browsers
                                they are using;
                            </p>
                        </li>
                        <li>
                            <p>Determine the number of unique users of the website;</p>
                        </li>
                        <li>
                            <p>Improve the website by measuring any errors that occur; and</p>
                        </li>
                        <li>
                            <p>Conduct research and diagnostics to improve product offerings.</p>
                        </li>
                    </ul>
                </div>
                <div className="terms-data ct-wr">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <span className="font-bold">Cookie name</span>
                                </th>
                                <th>
                                    <span className="font-bold">Source</span>
                                </th>

                                <th>
                                    <span className="font-bold">Expiry</span>
                                </th>
                                <th>
                                    <span className="font-bold">Purpose</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <p>_ga</p>
                                </td>

                                <td>
                                    <p>sync.ai</p>
                                </td>

                                <td>
                                    <p>Session</p>
                                </td>

                                <td>
                                    <p>
                                        Registers a uniqueID that is used to generate statistical data on how the
                                        visitor uses the website.
                                    </p>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <p>_gat</p>
                                </td>

                                <td>
                                    <p>sync.ai</p>
                                </td>

                                <td>
                                    <p>1 day</p>
                                </td>

                                <td>
                                    <p>Used by Google Analytics to throttle request rate</p>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <p>_gid</p>
                                </td>

                                <td>
                                    <p>sync.ai</p>
                                </td>
                                <td>
                                    <p>1 day</p>
                                </td>

                                <td>
                                    <p>
                                        Registers a uniqueID that is used to generate statistical data on how the
                                        visitor uses the website.
                                    </p>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <p>Collect</p>
                                </td>
                                <td>
                                    <p>google-analytics.com</p>
                                </td>

                                <td>
                                    <p>Session</p>
                                </td>
                                <td>
                                    <p>
                                        Used to send data to GoogleAnalytics about the visitor's device and
                                        behavior.Tracks the visitor across devices and marketing channels.
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>loglevel</p>
                                </td>

                                <td>
                                    <p>sync.ai</p>
                                </td>

                                <td>
                                    <p>Persistent</p>
                                </td>

                                <td>
                                    <p>
                                        Collects data on visitor interaction with the website'svideo-content -This data
                                        is used to make the website's video-content more relevant towards the visitor.
                                    </p>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <p>undefined</p>
                                </td>

                                <td>
                                    <p>sync.ai</p>
                                </td>

                                <td>
                                    <p>Persistent</p>
                                </td>

                                <td>
                                    <p>
                                        Collects data on visitor interaction with the website'svideo-content -This data
                                        is used to make the website's video-content more relevant towards the visitor
                                    </p>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <p>wistia</p>
                                </td>

                                <td>
                                    <p>sync.ai</p>
                                </td>

                                <td>
                                    <p>Persistent</p>
                                </td>

                                <td>
                                    <p>
                                        Used by the website to track the visitor's use of video-content -The cookie
                                        roots from Wistia, which provides video-software to websites.
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="terms-data ct-wr">
                    <p className="ol-list-type">3. Unclassified</p>
                </div>

                <div className="terms-data ct-wr">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <span className="font-bold">Cookie name</span>
                                </th>
                                <th>
                                    <span className="font-bold">Source</span>
                                </th>

                                <th>
                                    <span className="font-bold">Expiry</span>
                                </th>
                                <th>
                                    <span className="font-bold">Purpose</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <p>wistia-video-progress-#</p>
                                </td>

                                <td>
                                    <p>sync.ai</p>
                                </td>

                                <td>
                                    <p>Persistent</p>
                                </td>

                                <td>
                                    <p>Unclassified</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="terms-data ct-wr">
                    <h3>How to control or delete cookies</h3>
                </div>
                <div className="terms-data ct-wr">
                    <p>
                        Most browsers allow you to change your cookie settings. These settings will typically be found
                        in the“options” or “preferences” menu of your browser. In order to understand these settings and
                        learn how to use them, please consult the “Help” function of your browser, or the documentation
                        published online for your particular browser type and version.
                    </p>
                </div>
                <div className="terms-data ct-wr">
                    <p>
                        However, please note that if you choose to refuse cookies you may not be able to use the full
                        functionality of our Site.
                    </p>
                </div>
                <div className="terms-data ct-wr">
                    <p>
                        The following pages have information on how to change your cookies settings for the different
                        browsers:
                    </p>
                </div>
                <div className="terms-data ct-wr">
                    <ul>
                        <li>
                            <a
                                className="anchor-link"
                                target={"_blank"}
                                href="https://support.google.com/chrome/answer/95647?hl=en&ref_topic=14666"
                            >
                                Cookie settings in Chrome
                            </a>
                        </li>
                        <li>
                            <a
                                className="anchor-link"
                                target={"_blank"}
                                href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies"
                            >
                                Cookie settings in Firefox
                            </a>
                        </li>
                        <li>
                            <a
                                className="anchor-link"
                                target={"_blank"}
                                href="http://windows.microsoft.com/en-GB/internet-explorer/delete-manage-cookies#ie=ie-10"
                            >
                                Cookie settings in Internet Explorer
                            </a>
                        </li>
                        <li>
                            <a
                                target={"_blank"}
                                className="anchor-link"
                                href="https://support.apple.com/kb/PH5042?locale=en_US"
                            >
                                Cookie settings in Safari
                            </a>{" "}
                            and{" "}
                            <a
                                target={"_blank"}
                                className={"anchor-link"}
                                href={"https://support.apple.com/en-gb/HT201265"}
                            >
                                iOS
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="terms-data ct-wr">
                    <h3>Third Party Websites' Cookies</h3>
                </div>
                <div className="terms-data ct-wr">
                    <p>
                        When using our website, you may be directed to other websites for such activities as surveys, to
                        make payment in currency other than U.S. dollars, or for job applications. These websites may
                        use their own cookies. We do not have control over the placement of cookies by other websites
                        you visit, even if you are directed to them from our website
                    </p>
                </div>
                <div className="terms-data ct-wr">
                    <p>
                        If you use the buttons that allow you to share products and content with your friends via social
                        networks like Google, Twitter and Facebook, these companies may set a cookie on your computer
                        memory. Find out more about these here:
                    </p>
                </div>
                <div className="terms-data ct-wr">
                    <p>
                        <a href="https://www.facebook.com/about/privacy" className="anchor-link" target="_blank">
                            https://www.facebook.com/about/privacy
                        </a>
                    </p>
                    <p>
                        <a href="http://twitter.com/privacy" className="anchor-link" target="_blank">
                            http://twitter.com/privacy
                        </a>
                    </p>
                    <p>
                        <a
                            href="http://www.google.com/intl/en-GB/policies/privacy"
                            className="anchor-link"
                            target="_blank"
                        >
                            http://www.google.com/intl/en-GB/policies/privacy
                        </a>
                    </p>
                </div>
                <div className="terms-data ct-wr">
                    <h3>Need More Information?</h3>
                </div>
                <div className="terms-data ct-wr">
                    <p>
                        If you would like to find out more about cookies and their use on the Internet, you may find the
                        following link useful:
                    </p>
                </div>
                <div className="terms-data ct-wr">
                    <ul>
                        <li>
                            <a target={"_blank"} className="anchor-link" href="http://www.allaboutcookies.org/">
                                All About Cookies
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="terms-data ct-wr">
                    <h3>Cookies that have been set in the past</h3>
                </div>
                <div className="terms-data ct-wr">
                    <p>
                        If you have disabled one or more Cookies, we may still use information collected from cookies
                        prior to your disabled preference being set, however, we will stop using the disabled cookie to
                        collect any further information.
                    </p>
                </div>

                <div className="terms-data ct-wr">
                    <h3>Contact us</h3>
                    <p>
                        If you have any questions or comments about this cookies policy, or privacy matters generally,
                        please contact us via email at
                    </p>
                    <a href="mailto:privacy@sync.ai" className="anchor-link">
                        privacy@sync.ai
                    </a>
                </div>
            </div>
        </Layout>
    )
}
